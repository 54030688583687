// @flow

import React from 'react'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import SEO from '../../../components/seo'
import LandingpageNav from '../../../components/Landingpage/LandingpageNav'
import CustomContactBtn from '../../../components/Landingpage/CustomContactBtn'
import Landingpage from '../../../components/Landingpage'
import messages from '../../../messages/pageTitles.lang'
import metaDescription from '../../../messages/pageMetaDescriptions.lang'

import baseStyles from './e-commerce.scss'
import baseLandingpageMessages from '../../../components/Landingpage/Landingpage.lang'
import caseMessages from '../../../components/Landingpage/messages/E-commerce/E-commerce.lang'
import { StaticImage } from 'gatsby-plugin-image'

/* eslint-disable max-len */
const heroImg = <StaticImage src="../../../images/landingpage/heroBg.jpg" alt="" objectFit="cover" className="image" />
const descriptionImgSmall1 = (
  <StaticImage src="../../../images/landingpage/typing.jpg" alt="" objectFit="cover" className="image" />
)
const descriptionImgSmall2 = (
  <StaticImage src="../../../images/landingpage/conversion_statistics.jpg" alt="" objectFit="cover" className="image" />
)
const benefitsImg = (
  <StaticImage src="../../../images/landingpage/girl_laughing.jpg" alt="" objectFit="cover" className="image" />
)
const descriptionImgBig = (
  <StaticImage
    src="../../../images/landingpage/writing_on_window.jpg"
    alt=""
    objectFit="cover"
    className="imageCover"
  />
)
const imgStrapi = (
  <StaticImage src="../../../images/landingpage/man_on_mcbook.jpg" alt="" objectFit="cover" className="image" />
)
/* eslint-enable max-len */

type Props = {
  ...StyleProps
}

const data = {
  heroTeaser: {
    img: heroImg,
    title: caseMessages.heroTitle,
    subtitle: caseMessages.heroSubtitle
  },
  descriptionTeaser: {
    title: caseMessages.descriptionTitle,
    text: caseMessages.descriptionText,
    displayButton: false,
    button: caseMessages.descriptionButton,
    imgBig: descriptionImgBig,
    imgSmall1: descriptionImgSmall1,
    imgSmall2: descriptionImgSmall2
  },
  valueBox: {
    title: baseLandingpageMessages.valueBoxTitle,
    subtitle: caseMessages.valueBoxSubtitle,
    title1: caseMessages.valueBoxTitle1,
    title2: caseMessages.valueBoxTitle2,
    title3: caseMessages.valueBoxTitle3,
    values1: [caseMessages.productValues1A, caseMessages.productValues1B, caseMessages.productValues1C],
    values2: [caseMessages.productValues2A, caseMessages.productValues2B, caseMessages.productValues2C],
    values3: [caseMessages.productValues3A, caseMessages.productValues3B, caseMessages.productValues3C]
  },
  benefitsTeaser: {
    img: benefitsImg,
    title: caseMessages.benefitsTitle,
    text: caseMessages.benefitsText,
    button: caseMessages.benefitsButton,
    reversed: true
  },
  strapiTeaser: {
    title: caseMessages.strapiTitle,
    text: caseMessages.strapiText,
    img: imgStrapi,
    reversed: true
  },
  contact: {
    title: caseMessages.contactTitle,
    subtitle: caseMessages.contactSubtitle,
    headline: caseMessages.contactheadline
  }
}

const LandingEcommerce = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout
      footer={<Footer />}
      header={<Header withTeaser hasCustomNav customNav={<LandingpageNav />} contactBtn={<CustomContactBtn />} />}
    >
      <SEO
        title={formatMessage(messages.TitleEcommerce)}
        description={formatMessage(metaDescription.ecommerceDescription)}
        lang={locale}
      />
      <Landingpage data={data} styles={styles.landing} />
    </Layout>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(LandingEcommerce)
